<template>
  <div class="page">
    <Navbar title="图片分享" type="SMP" />
    <div class="main" ref="main" v-if="display === 'main'">
      <van-image
        width="100%"
        :src="require('../../../../assets/images/train/share-top.jpg')"
      ></van-image>
      <div class="content">
        <div class="title">{{ share.title }}</div>
        <div class="duration color-s2">拿证周期：{{ share.duration }}天</div>
        <div class="label">
          <van-tag :color="COLOR_S1" v-for="item in share.label" :key="item">{{
            item
          }}</van-tag>
        </div>
        <div class="qrcode">
          <div class="tips color-s1">长按图片识别二维码查看证书明细信息</div>
          <div class="img" ref="qrcodeImg"></div>
        </div>
      </div>
    </div>
    <div class="share-img">
      <img ref="shareimg" />
    </div>
    <div class="step">
      <div class="title">推荐步骤：</div>
      <div class="text">
        1、长按上方图片保存到本地或直接分享给好友、微信群、朋友圈进行推荐
      </div>
      <div class="text">
        2、用户通过识别二维码下单并完成业务后推荐人可直接领取现金奖励
      </div>
    </div>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Image, Tag } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Image.name]: Image,
    [Tag.name]: Tag
  },
  data () {
    return {
      taskCode: '',
      display: 'main',
      share: {},
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.taskCode = query.taskCode
    var wth = document.body.clientWidth
    this.retrieveTask()
    this.initQrcode(wth)
  },
  methods: {
    async retrieveTask () {
      this.loadingShow = true
      var that = this
      var pd = { taskCode: that.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveShareTask', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.share = res.data
        setTimeout(() => {
          this.saveImage()
        }, 3000)
        // this.loadingShow = false
      }
    },
    initQrcode (wth) {
      var link = this.initLink()
      this.$refs.qrcodeImg.innerHTML = ''
      var qwidth = (200 / 375) * wth
      var vn = new QRCode(this.$refs.qrcodeImg, {
        text: link,
        width: qwidth,
        height: qwidth,
        colorDark: '#000000',
        colorLight: '#ffffff'
      })
      console.log(vn)
    },
    initLink () {
      var link = 'https://moc.utopanet.com/train/detail?taskCode=' + this.taskCode
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var site = window.sessionStorage.getItem(this.SESSION_SITE)
      link = link + '&sourceType=RFE&source=RFE_DETAIL'
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (site !== undefined && site !== null && site !== '') {
        link = link + '&site=' + site
      }
      if (user !== undefined && user !== null && user !== '') {
        link = link + '&referee=' + user
      }
      return link
    },
    saveImage () {
      this.loadingShow = false
      this.display = 'share'
      html2canvas(this.$refs.main, {
        backgroundColor: null, // 画出来的图片有白色的边框,不要可设置背景为透明色（null）
        useCORS: true, // 支持图片跨域
        scale: 1 // 设置放大的倍数
      }).then((canvas) => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        const url = canvas.toDataURL('image/png') // toDataURL: 图片格式转成 base64
        this.imgUrl = url
        this.$refs.shareimg.src = url
        // 将图片下载到本地
        // const a = document.createElement('a') // 生成一个a元素
        // const event = new MouseEvent('click') // 创建一个单击事件
        // a.download = 'tttt1111' // 设置图片名称没有设置则为默认
        // a.href = this.imgUrl // 将生成的URL设置为a.href属性
        // a.dispatchEvent(event) // 触发a的单击事件
      })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: #142c96;
  padding-bottom: 20px;
}
.main {
  background-color: #142c96;
  padding-bottom: 20px;
  .content {
    width: 96%;
    margin-left: 2%;
    background-color: #fff;
    border-radius: 10px;
    .title {
      padding: 20px 10px 0px 10px;
      font-size: 22px;
      font-weight: 800;
    }
    .duration {
      padding: 15px 0px 10px 0px;
      font-size: 22px;
      font-weight: 800;
    }
    ::v-deep .label {
      width: 80%;
      margin-left: 10%;
      .van-tag {
        margin-right: 3px;
        font-size: 16px;
        padding: 3px 6px;
        font-weight: 600;
      }
    }
    .qrcode {
      text-align: center;
      padding: 10px 0px 20px 0px;
      .tips {
        font-size: 16px;
        font-weight: 600;
      }
      .img {
        margin: 10px auto;
        width: 200px;
        height: 200px;
      }
    }
  }
}
.step {
  text-align: left;
  width: 96%;
  margin-left: 2%;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0px;
  .title {
    padding: 0px 0px 5px 20px;
    font-size: 15px;
    font-weight: 600;
  }
  .text {
    padding: 2px 20px 2px 20px;
  }
}
</style>
